export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const CLEAR_SNACKBAR = "CLEAR_SNACKBAR";

export const STORE_REQUIRED_DATA = "STORE_REQUIRED_DATA";

export const REDIRECT_TO = "REDIRECT_TO";

export const LIST_TRANSACTIONS_DATA = "LIST_TRANSACTIONS_DATA";
export const RESET_TRANSACTIONS_LIST_DATA = "RESET_TRANSACTIONS_LIST_DATA";

export const SHOW_WALLETS_DATA = "SHOW_WALLETS_DATA";
export const RESET_WALLETS_DATA = "RESET_WALLETS_DATA";

export const LIST_COMP_TOPUPS_DATA = "LIST_COMP_TOPUPS_DATA";
export const LIST_EMP_TOPUPS_DATA = "LIST_EMP_TOPUPS_DATA";
export const RESET_CREDITS_DATA = "RESET_CREDITS_DATA";

export const SHOW_PROFILE_DATA = "SHOW_PROFILE_DATA";
export const SHOW_KIT_DETAILS = "SHOW_KIT_DETAILS";
export const SHOW_CARD_STATUS = "SHOW_CARD_STATUS";
export const RESET_PROFILE_DATA = "RESET_PROFILE_DATA";