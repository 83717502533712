import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { resetCreditsData } from 'store/actions';
import { listAllCompTopups } from 'store/actions';
import { CompanyCards } from './creditsListingCards';

function CompanyCardContent() {
    const dispatch = useDispatch();

    const { compData } = useSelector(({ credits: { compTopups } }) => ({
        compData: compTopups,
    }));

    useEffect(() => {
        dispatch(resetCreditsData())
        dispatch(listAllCompTopups());
    }, []);
  return (
    <>
    <CompanyCards compTopups={compData} />
    </>
  )
}

export default CompanyCardContent