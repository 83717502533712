import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, Container, Grid } from '@material-ui/core';
import CustomHeader from 'components/Headers/CustomHeader'

import { fetchWalletData } from 'store/actions';
import { makeStyles } from '@material-ui/styles';
import componentStyles from "assets/theme/views/admin/tables.js";
import { resetWalletsData } from 'store/actions';

const useStyles = makeStyles(componentStyles);

const cardStyle = {
    textAlign: "center", 
    minHeight: "200px", 
    fontSize: "24px", 
    fontWeight: "bold",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
}

function Wallets() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { walletsData } = useSelector(({ wallets: { walletsData } }) => ({
        walletsData: walletsData,
    }));
    
    useEffect(() => {
        dispatch(resetWalletsData());
        dispatch(fetchWalletData());
    }, []);

  return (
    <>
    <CustomHeader />
          <Container maxWidth={false} component={Box} marginTop="-6rem" classes={{ root: classes.containerRoot }}>
              <Grid container spacing={3}>
                  <Grid item style={{ width: "100%" }}>
                      <Card style={ cardStyle }>
                              <Grid container justify='center'>
                                  <Grid item lg={11} xs={11}>
                                      <h4>EXPENSE</h4>
                                      <h4 style={{ backgroundColor: "#E6E6FA", padding: "20px", borderRadius: "7px" }}>₹{walletsData?.expense}</h4>
                                  </Grid>
                              </Grid>
                      </Card>
                  </Grid>
              </Grid>
          </Container>
    </>
  )
}

export default Wallets