import { TOGGLE_LOADING, LIST_COMP_TOPUPS_DATA, LIST_EMP_TOPUPS_DATA, RESET_CREDITS_DATA } from "../types";
import { paysackGet } from "services";
import { compTopupsRoute, pgTxnsRoute  } from "helpers/constants";

export const listAllCompTopups = () => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const { _id } = getState().auth;

        let apiResponse = await paysackGet(
            `${compTopupsRoute}${_id}`
        );

        if (apiResponse.data.customStatus) {
            dispatch({
                type: LIST_COMP_TOPUPS_DATA,
                payload: apiResponse.data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const listAllEmpTopups = () => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const { _id } = getState().auth;

        let apiResponse = await paysackGet(
            `${pgTxnsRoute}${_id}`
        );

        if (apiResponse.data.customStatus) {
            dispatch({
                type: LIST_EMP_TOPUPS_DATA,
                payload: apiResponse.data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetCreditsData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_CREDITS_DATA });
    };
};