import { LIST_TRANSACTIONS_DATA, RESET_TRANSACTIONS_LIST_DATA } from "../types";

const INITIAL_STATE = {
    list: [],
};

const transactionsReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_TRANSACTIONS_DATA:
            return {
                ...state,
                list: payload,
            };

        case RESET_TRANSACTIONS_LIST_DATA:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default transactionsReducer;
