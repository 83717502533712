export * from "./isLogged";
export * from "./history";
export * from "./csvToJson";
export * from "./dateFormatter";

export const currencyFormat = (number, prefix = "₹") => {
    // return new Intl.NumberFormat("en-IN", {
    //   style: "currency",
    //   currency: "INR",
    // }).format(number);
    if (typeof number === "undefined") {
        number = 0;
    }
    return (
        prefix +
        Number(number)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    );
};
