export const getConfig = (token) => ({
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'x-access-token': token,
      'Content-Type': 'application/json'
    }
  })
  
  export const postConfig = (body, token) => ({
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': token
    },
    body: JSON.stringify(body)
  })