import { Grid, Link } from '@material-ui/core';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { resetTxnsData, listAllTxns } from "store/actions";
import { currencyFormat } from "utils";
import { formatDateAndTime } from "utils";
import Personal from '../../images/Card.png'

const card = {
    cont: {
        padding: "10px",
    },
    div: {
        backgroundColor: "#FFFFFF",
        padding: "20px",
        borderRadius: "10px",
        fontSize: "12px",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)"
    },
    img: {
        width: "40px",
        marginTop: "15px"
    },
    link: {
        textDecoration: "none",
        cursor: "pointer",
        color: "blue"
    }
}

function CardView() {
    const dispatch = useDispatch();

    const { txnsData } = useSelector(({ transactions: { list } }) => ({
        txnsData: list,
    }));
    
    useEffect(() => {
        dispatch(resetTxnsData());
        fetchMoreData();
    }, []);

    const fetchMoreData = () => {
        dispatch(listAllTxns());
    };

    const renderType = (txn) => {
        if (txn.tags) {
            if (txn.tags.length > 0) {
                if (txn.tags[0] === '') return 'Personal Expense'
            }
        }
        return 'Personal Expense'
    }

    return (
        <>
            {txnsData.length?
            <Grid container style={card.cont} spacing={2}>
                {txnsData.map(txn =>
                    <Grid item lg={4} md={6} xs={12} key={txn._id}>
                        <div style={card.div}>
                            <Grid container>
                                <Grid item lg={6} xs={6}>
                                    <strong>{currencyFormat(txn.amount)}</strong>
                                </Grid>
                                <Grid item lg={6} xs={6}>
                                    <div style={{ textAlign: "right" }}>
                                        <a href={`/app/txn-details/${txn._id}`} style={card.link}>View details</a>
                                    </div>
                                </Grid>
                            </Grid>
                            <hr />
                            <Grid container>
                                <Grid item lg={4} xs={4}>
                                    <img src={Personal} style={card.img} alt="" />
                                </Grid>
                                <Grid item lg={8} xs={8}>
                                    <p>{renderType(txn)}</p>
                                    <p>{formatDateAndTime(txn?.txnDate)}</p>
                                </Grid>
                            </Grid>
                        </div>
                        <Grid />
                    </Grid>)}
            </Grid>:
                <div style={card.div}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <h4 style={{ fontSize: "15px", fontWeight: "bold", fontFamily: "inherit" }}>No data available</h4>
                    </div>
                </div>}
        </>
    )
}

export default CardView