import React from 'react'
export const loginRoute = 'v1/authenticate'
export const authRoute = 'v1/auth_user'
export const merchTxnsRoute = 'v1/merchant_transactions?employee='
export const reimbursementsRoute = 'v1/comp_emp_txns?txn_type=reimbursement&employee='
export const compTopupsRoute = 'v1/comp_emp_txns?txn_type=topup&txn_type=refund&approval_status=approved&employee='
export const filesRoute = 'v1/files'
export const passResetRoute = 'v1/authenticate/resetPassword'
export const passForgotRoute = 'v1/authenticate/forgotPassword'
export const empDetRoute = 'v1/employees/me'
export const empWalletRoute = 'v1/employees'
export const undoReimRoute = 'v1/company_reimbursement'
export const pgTxnsRoute = 'v1/payment_gateway_txn?employee='
export const otpAttachRoute = 'v1/otp/attach'
export const cardKitRoute = 'v1/card-kit'
export const otpRoute = 'v1/otp/attach'