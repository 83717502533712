import { combineReducers } from "redux";
import { LOGOUT } from "../types";
import loadingReducer from "./loadingReducer";
import authReducer from "./authReducer";
import sidebarReducer from "./sidebarReducer";
import transactionsReducer from "./transactionsReducer";
import walletsReducer from "./walletsReducer";
import creditsReducer from "./creditsReducer";
import profileReducer from "./profileReducer";

const appReducer = combineReducers({
    auth: authReducer,
    loading: loadingReducer,
    sidebar: sidebarReducer,
    transactions: transactionsReducer,
    wallets: walletsReducer,
    credits: creditsReducer,
    profile: profileReducer,
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
