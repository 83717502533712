import React from 'react'
import { Card, Grid } from '@material-ui/core'
import CompIcon from '../../images/Bank.png'
import EmpIcon from '../../images/Bank-1.png'

const card = {
  cont: {
    padding: "10px",
    marginTop: "15px"
  },
  div: {
    backgroundColor: "#FFFFFF",
    padding: "20px",
    borderRadius: "10px",
    fontSize: "12px",
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
  },
  img: {
    width: "40px",
    marginTop: "20px"
  }
}

const renderTime = (time = new Date()) => {
  return (`${new Date(parseInt(time * 1000, 10))}`).slice(0, 21)
}

export function CompanyCards({ compTopups }) {

  return (
    <>
      {compTopups.length ?
        <Grid container style={card.cont} spacing={2}>
          {compTopups.map(txn =>
            <Grid item lg={4} md={6} xs={12} key={txn._id}>
              <div style={card.div}>
                <Grid container>
                  <Grid item lg={3} xs={3}>
                    <img src={`${CompIcon}`} style={card.img} alt="" />
                  </Grid>
                  <Grid item lg={9} xs={9}>
                    <p>Your company credited <span style={{ fontWeight: 'bold' }}>₹{txn.amount}</span> to your <span style={{ fontWeight: 'bold' }}>{txn.amount_type.toUpperCase()}</span> wallet.</p>
                    <p>{renderTime(txn.initiated_ts)}</p>
                  </Grid>
                </Grid>
              </div>
              <Grid />
            </Grid>)}
        </Grid> :
        <Card style={{ marginTop: "30px", padding: "20px" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h4 style={{ fontSize: "15px", fontWeight: "bold", fontFamily: "inherit" }}>No data available</h4>
          </div>
        </Card>}
    </>
  )
}


export function PersonalCards({ empTopups }) {

  return (
    <>
      {empTopups.length ?
        <Grid container style={card.cont} spacing={2}>
          {empTopups.map(txn =>
            <Grid item lg={4} md={6} xs={12} key={txn._id}>
              <div style={card.div}>
                <Grid container>
                  <Grid item lg={3} xs={3}>
                    <img src={`${EmpIcon}`} style={card.img} alt="" />
                  </Grid>
                  <Grid item lg={9} xs={9}>
                    <p>You did a topup of <span style={{ fontWeight: 'bold' }}>₹{txn.amount}</span> to your <span style={{ fontWeight: 'bold' }}>EXPENSE</span> wallet.</p>
                    <p>{renderTime(txn.initiated_ts)}</p>
                  </Grid>
                </Grid>
              </div>
              <Grid />
            </Grid>)}
        </Grid> :
        <Card style={{ marginTop: "30px", padding: "20px" }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h4 style={{ fontSize: "15px", fontWeight: "bold", fontFamily: "inherit" }}>No data available</h4>
          </div>
        </Card>}
    </>
  )
}
