import { Grain, VpnKey, AccountBalanceWallet, CompareArrows, CreditCard, AccountCircle, Info } from "@material-ui/icons";

import Login from "views/auth/Login.js";
import Credits from "views/credits";
import Transactions from "views/transactions";
import Wallets from "views/wallets";
import Profile from "views/profile";
import About from "views/about";
import TxnDetails from "views/transactions/txnDetails";

var adminRoutes = [
    {
        path: "/dashboard",
        name: "Transactions",
        icon: CompareArrows,
        iconColor: "Primary",
        component: Transactions,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/wallets",
        name: "Wallets",
        icon: AccountBalanceWallet,
        iconColor: "Primary",
        component: Wallets,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/credits",
        name: "Credits",
        icon: CreditCard,
        iconColor: "Primary",
        component: Credits,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/profile",
        name: "My Profile",
        icon: AccountCircle,
        iconColor: "Primary",
        component: Profile,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/about",
        name: "About",
        icon: Info,
        iconColor: "Primary",
        component: About,
        layout: "/app",
        visibility: true,
    },
    {
        path: "/txn-details/:id",
        name: "Transaction Details",
        icon: Grain,
        iconColor: "Primary",
        component: TxnDetails,
        layout: "/app",
        visibility: false,
    },
    {
        divider: true,
        layout: "/app",
        visibility: true,
    },
];

var authRoutes = [
    {
        path: "/login",
        name: "Login",
        icon: VpnKey,
        iconColor: "Info",
        component: Login,
        layout: "/auth",
    },
];
export { adminRoutes, authRoutes };
