import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { resetCreditsData } from 'store/actions';
import { listAllEmpTopups } from 'store/actions';
import { PersonalCards } from './creditsListingCards';

function PersonalCardContent() {
  const dispatch = useDispatch();

  const { empData } = useSelector(({ credits: { empTopups } }) => ({
    empData: empTopups,
  }));

  useEffect(() => {
    dispatch(resetCreditsData())
    dispatch(listAllEmpTopups());
  }, []);
  return (
    <>
    <PersonalCards empTopups={empData} />
    </>
  )
}

export default PersonalCardContent