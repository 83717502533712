import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { resetTxnsData, listAllTxns } from "store/actions";
import CustomTable from "components/CustomTable/CustomTable";
import { currencyFormat } from "utils";
import { formatDateAndTime } from "utils";

function TableView() {
    const dispatch = useDispatch();

    const { txnsData } = useSelector(({ transactions: { list } }) => ({
        txnsData: list,
    }));

    useEffect(() => {
        dispatch(resetTxnsData());
        fetchMoreData();
    }, []);

    const fetchMoreData = () => {
        dispatch(listAllTxns());
    };
    return (
        <>
            <CustomTable
                title="Transactions"
                columns={[
                    { title: "Time, Date", render: (rowData) => <p>{formatDateAndTime(rowData?.txnDate)}</p> },
                    { title: "Merchant Name", field: "merchantName" },
                    { title: "MCC", field: "mcc" },
                    { title: "RRN", field: "retrievalRefNo" },
                    { title: "Merchant ID", field: "merchantId" },
                    { title: "Amount", render: (rowData) => <p>{currencyFormat(rowData?.amount)}</p> },
                    { title: "Status", field: "txnStatus" },
                ]}
                data={txnsData}
                exportable
            />
        </>
    )
}

export default TableView