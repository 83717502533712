import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Grid, Card, Tab, FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { Stack, TextField } from "@mui/material";
import DateAdapter from "@mui/lab/AdapterMoment";
import { DatePicker, LocalizationProvider } from "@mui/lab";

// core components
import CustomHeader from "components/Headers/CustomHeader";

import componentStyles from "assets/theme/views/admin/tables.js";
import CustomTable from "components/CustomTable/CustomTable";
import { resetTxnsData, listAllTxns } from "store/actions";
import { currencyFormat } from "utils";
import { formatDateAndTime } from "utils";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import TableView from "./tableView";
import CardView from "./cardView";

const useStyles = makeStyles(componentStyles);

function Transactions() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [value, setValue] = useState('1');
    const [startDate, setStartDate] = useState(moment().subtract(5, "day"));
    const [endDate, setEndDate] = useState(moment());

    // const { txnsData } = useSelector(({ transactions: { list } }) => ({
    //     txnsData: list,
    // }));

    // useEffect(() => {
    //     dispatch(resetTxnsData());
    //     fetchMoreData();
    // }, [startDate, endDate]);

    // const fetchMoreData = () => {
    //     dispatch(listAllTxns({ startDate: startDate.format("YYYYMMDDHHMMSS"), endDate: endDate.format("YYYYMMDDHHMMSS") }));
    // };

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <>
            <CustomHeader />
            <Container maxWidth={false} component={Box} marginTop="-6rem" classes={{ root: classes.containerRoot }}>
                <Grid container justify="flex-start" spacing={3}>
                    <Grid item xs={6} md={4} lg={3}>
                        <Card>
                            <Grid container spacing={3}>
                                <Grid item style={{ padding: "1.5em" }}>
                                    <LocalizationProvider dateAdapter={DateAdapter}>
                                        <Stack spacing={3}>
                                            <DatePicker
                                                label="Start Date"
                                                value={startDate}
                                                onChange={(date) => {
                                                    setStartDate(date);
                                                }}
                                                minDate={moment().subtract(90, "days")}
                                                maxDate={moment()}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={6} md={4} lg={3}>
                        <Card>
                            <Grid container spacing={3}>
                                <Grid item style={{ padding: "1.5em" }}>
                                    <LocalizationProvider dateAdapter={DateAdapter}>
                                        <Stack spacing={3}>
                                            <DatePicker
                                                label="End Date"
                                                value={endDate}
                                                onChange={(date) => {
                                                    setEndDate(date);
                                                }}
                                                minDate={moment()}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </Stack>
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <TabContext value={value}>
                              <Card >
                                  <small style={{marginLeft:"10px"}}>View as</small>
                                  <TabList onChange={handleChange} style={{marginTop:"-7px"}}>
                                      <Tab label="Cards" value="1" />
                                      <Tab label="Table" value="2" />
                                  </TabList>
                              </Card>
                          </TabContext>
                    </Grid>
                </Grid>
                <br />
                {value=== "1" ? <CardView/>:<TableView/>}
                {/* <CustomTable
                    title="Transactions"
                    columns={[
                        { title: "Time, Date", render: (rowData) => <p>{formatDateAndTime(rowData?.txnDate)}</p> },
                        { title: "Merchant Name", field: "merchantName" },
                        { title: "MCC", field: "mcc" },
                        { title: "RRN", field: "retrievalRefNo" },
                        { title: "Merchant ID", field: "merchantId" },
                        { title: "Amount", render: (rowData) => <p>{currencyFormat(rowData?.amount)}</p> },
                        { title: "Status", field: "txnStatus" },
                    ]}
                    data={txnsData}
                    exportable
                /> */}
            </Container>
        </>
    );
}

export default Transactions;
