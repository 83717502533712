import { LIST_COMP_TOPUPS_DATA, LIST_EMP_TOPUPS_DATA, RESET_CREDITS_DATA } from "../types";

const INITIAL_STATE = {
    compTopups: [],
    empTopups: [],
};

const creditsReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case LIST_COMP_TOPUPS_DATA:
            return {
                ...state,
                compTopups: payload,
            };
        case LIST_EMP_TOPUPS_DATA:
            return {
                ...state,
                empTopups: payload,
            };

        case RESET_CREDITS_DATA:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default creditsReducer;
