import { SHOW_PROFILE_DATA, SHOW_KIT_DETAILS, RESET_PROFILE_DATA, SHOW_CARD_STATUS } from "../types";

const INITIAL_STATE = {
    profileData: null,
    kitDetails: null,
    cardStatus: null,
};

const profileReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SHOW_PROFILE_DATA:
            return {
                ...state,
                profileData: payload,
            };

        case SHOW_KIT_DETAILS:
            return {
                ...state,
                kitDetails: payload,
            };

        case SHOW_CARD_STATUS:
            return {
                ...state,
                cardStatus: payload,
            };

        case RESET_PROFILE_DATA:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default profileReducer;
