import { TOGGLE_LOADING, SHOW_WALLETS_DATA, RESET_WALLETS_DATA } from "../types";
import { paysackGet } from "services";
import { empWalletRoute } from "helpers/constants";

export const fetchWalletData = () => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const { _id } = getState().auth;
        
        let apiResponse = await paysackGet(
            `${empWalletRoute}/${_id}/wallet`
        );
        
        if (apiResponse.data.customStatus) {
            dispatch({
                type: SHOW_WALLETS_DATA,
                payload: apiResponse.data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetWalletsData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_WALLETS_DATA });
    };
};
