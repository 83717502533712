import React from 'react'
import CustomHeader from 'components/Headers/CustomHeader'
import { Box, Card, Container, Grid } from '@material-ui/core'
import { version } from '../../../package.json'
import image from '../../images/profile-default.png'

const cardStyle = {
    textAlign: "center", 
    minHeight: "200px", 
    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
}

function About() {
  return (
    <>
    <CustomHeader/>
    <Container maxWidth={false} component={Box} marginTop="-6rem">
              <Grid container spacing={3} justify="center">
                  <Grid item style={{ width: "100%" }}>
                      <Card style={cardStyle}>
                          <Grid container justify='center'>
                              <Grid item lg={11}>
                                  <img src={image} alt=""/>
                              </Grid>
                              <Grid item lg={12} xs={12}>
                                  <p>version {version}</p>
                              </Grid>
                          </Grid>
                      </Card>
                  </Grid>
              </Grid>
          </Container>
    </>
  )
}

export default About