import { toast } from "react-toastify";
import { TOGGLE_LOADING, LIST_TRANSACTIONS_DATA, RESET_TRANSACTIONS_LIST_DATA } from "../types";
import { paysackGet } from "services";
import { merchTxnsRoute } from "helpers/constants";

export const listAllTxns = (requestData) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        // const { list } = getState().transactions;
        const { _id } = getState().auth;

        let apiResponse = await paysackGet(
            `${merchTxnsRoute}${_id}`
        );

        if (apiResponse.data.customStatus) {
            dispatch({
                type: LIST_TRANSACTIONS_DATA,
                payload: apiResponse.data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetTxnsData = () => {
    return async (dispatch, getState) => {
        dispatch({ type: RESET_TRANSACTIONS_LIST_DATA });
    };
};
