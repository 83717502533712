import { LOGIN_SUCCESS, STORE_REQUIRED_DATA } from "../types";

const INITIAL_STATE = {
    token: null,
    _id: null,
    userId: null,
    username: null,
    userDetails: null,
};

const authReducer = (state = INITIAL_STATE, { type, payload, id, userId, username }) => {
    switch (type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                token: payload,
            };
        case STORE_REQUIRED_DATA:
            return {
                ...state,
                _id: id,
                userId: userId,
                username: username,
                userDetails: payload
            };
        default:
            return state;
    }
};

export default authReducer;
