import { TOGGLE_LOADING, SHOW_PROFILE_DATA, RESET_PROFILE_DATA, SHOW_KIT_DETAILS, SHOW_CARD_STATUS } from "../types";
import { paysackGet } from "services";
import { empDetRoute, cardKitRoute, loginRoute } from "helpers/constants";
import { postConfig } from "helpers/configs";
import { paysackBaseUrl } from "helpers";
import { history } from "utils";
import { toast } from "react-toastify";

export const fetchUserProfileData = () => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        
        let empDetails = await paysackGet(
            `${empDetRoute}`
        );

        let cardStatus = await paysackGet(
            `${cardKitRoute}/list?entityId=${empDetails.data.kit.entityId}`
        )
        
        if (empDetails.data.customStatus) {
            dispatch({
                type: SHOW_PROFILE_DATA,
                payload: empDetails.data,
            });
            dispatch({
                type: SHOW_CARD_STATUS,
                payload: cardStatus.data,
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const sendResetEmail = () => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const { username, token } = getState().auth;
        const payload = postConfig({ username }, token)

        let apiResponse = fetch(`${paysackBaseUrl}${loginRoute}/resetPassword`, payload)
        
        if (apiResponse) {
            history.push("/auth/login")
            toast.success('Password reset link has been sent to your inbox')
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const changeCardStatus = (entityId, kitNo, flag, reason) => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });

        const body = {
            entityId, kitNo, flag, reason
        }
        const { token } = getState().auth;
        const payload = postConfig(body, token)

        try{
            const response = await (await fetch(`${paysackBaseUrl}${cardKitRoute}/lock`, payload)).json()
        } catch (err) {
            toast.error("something went wrong");
            dispatch({ type: TOGGLE_LOADING, payload: false });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};


export const resetUserProfileData = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_PROFILE_DATA});
    };
};
