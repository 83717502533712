import { Box, Button, ButtonGroup, Card, Container, Grid, Modal } from '@material-ui/core'
import CustomHeader from 'components/Headers/CustomHeader'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchCardStatus } from 'store/actions';
import { fetchUserProfileData, resetUserProfileData } from 'store/actions';
import { fetchKitDetails, sendResetEmail, changeCardStatus } from 'store/actions';

const style = {
    card: {
        padding: "20px",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    },
    img: {
        textAlign: "center",
        paddingTop: "15px"
    },
    tick: {
        fontSize: "16px",
        color: "blue"
    },
    exc: {
        fontSize: "16px",
        color: "red"
    },
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        padding: "30px",
        transform: 'translate(-50%, -50%)',
        width: 350,
        backgroundColor: '#FFFFFF',
        borderRadius: "10px",
        boxShadow: 24,
        p: 4,
    }
}

function Profile() {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [cardLockModal,setCardLockModal] = useState(false)

    const { profileData,  cardStatus } = useSelector(({ profile: { profileData, cardStatus } }) => ({
        profileData: profileData,
        cardStatus: cardStatus
    }));

    useEffect(() => {
        dispatch(resetUserProfileData());
        dispatch(fetchUserProfileData());
        
    }, [cardLockModal]);

    const renderLockButton = () => {
        if (cardStatus?.cardStatusList[0] === 'ALLOCATED') {
          return 'Lock Card'
        } else if (cardStatus?.cardStatusList[0] === 'LOCKED') {
          return 'Unlock Card'
        }
        return 'Lock/Unlock Card'
      }

    const handleResetPassword = () => {
        dispatch(sendResetEmail())
    }

    const handleLockCard = () => {
        if (profileData) {
            const { entityId, kitNo } = profileData.kit
              dispatch(changeCardStatus(entityId, kitNo, cardStatus?.cardStatusList[0] === 'ALLOCATED' ? 'L' : 'UL', "cardStatusChangeReason"))
              setCardLockModal(false)
          }
    }
    return (
        <>
            <CustomHeader />
            <Container maxWidth={false} component={Box} marginTop="-6rem">
                <Grid container spacing={3} justify="center">
                    <Grid item style={{ width: "100%" }}>
                        <Card style={style.card}>
                            <Grid container>
                                <Grid item lg={3} xs={12} style={style.img}>
                                    <img src="https://i.ibb.co/DtH1tD2/image.png" width="150px" alt="" />
                                </Grid>
                                <Grid item lg={9} xs={12}>
                                    <h4>{profileData?.first_name}{" "}{profileData?.last_name}</h4>
                                    <hr />
                                    <p><i className='fa fa-envelope' /> <span />{profileData?.email} &nbsp; {profileData?.user.email_verification === 'approved' ? <span style={style.tick}> <i className='fa fa-check-circle' /></span> : <span style={style.exc}><i className='fa fa-exclamation-circle' /></span>}</p>
                                    <p><i className='fa fa-phone' /> <span />{profileData?.mobile}  &nbsp; {profileData?.user.phone_verification === 'approved' ? <span style={style.tick}> <i className='fa fa-check-circle' /></span> : <span style={style.exc}><i className='fa fa-exclamation-circle' /></span>}</p>
                                    <p><i className='fa fa-circle' /> <span />{profileData?.gender}</p>
                                    <p><i className='fa fa-id-card' /> <span />Kit number {profileData?.kit?.kitNo}</p>
                                    &nbsp;
                                    <div style={{ fontSize: 13 }}>
                                        <p>{profileData?.kit?.address}</p>
                                        <div style={{ lineHeight: "6px" }}>
                                            <p>{profileData?.kit?.city}</p>
                                            <p>{profileData?.kit?.state}</p>
                                            <p>{profileData?.kit?.country}</p>
                                            <p>Pincode {profileData?.kit?.pincode}</p>
                                        </div>
                                    </div>
                                    &nbsp;
                                    <Grid container>
                                        <Grid item lg={12}>
                                            <ButtonGroup >
                                                <Button onClick={() => setOpen(true)}>
                                                    Reset Password
                                                </Button>
                                                <Button onClick={() => setCardLockModal(true)}>
                                                    {renderLockButton()}
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <Box style={style.modal}>
                        <strong>Continue?</strong>
                        <p>You will be logged out of this session.</p>
                        <Grid container>
                            <Grid item lg={5} xs={5} />
                            <Grid item lg={4} xs={4}>
                                <Button onClick={() => setOpen(false)}>cancel</Button>
                            </Grid>
                            <Grid item lg={3} xs={3}>
                                <Button variant='contained' onClick={handleResetPassword}>ok</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                <Modal
                    open={cardLockModal}
                    onClose={() => setCardLockModal(false)}
                >
                    <Box style={style.modal}>
                        <strong>{renderLockButton()}?</strong>
                        <Grid container>
                            <Grid item lg={5} xs={5} />
                            <Grid item lg={4} xs={4}>
                                <Button onClick={() => setOpen(false)}>cancel</Button>
                            </Grid>
                            <Grid item lg={3} xs={3}>
                                <Button variant='contained' onClick={handleLockCard}>ok</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </Container>
        </>
    );
}

export default Profile