import React, { useState } from 'react'
import CustomHeader from 'components/Headers/CustomHeader'
import { Box, Card, Container, Grid, Tab } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import CompanyCardContent from './companyCardContent';
import PersonalCardContent from './personalCardContent';

function Credits() {
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

  return (
    <div>
          <CustomHeader />
          <Container maxWidth={false} component={Box} marginTop="-6rem">
              <Grid container spacing={3} justify="center">
                  <Grid item style={{ width: "100%" }}>
                      <div>
                          <TabContext value={value}>
                              <Card >
                                  <TabList onChange={handleChange}>
                                      <Tab label="Company" value="1" />
                                      <Tab label="Chargeback" value="2" />
                                      <Tab label="Personal" value="3" />
                                  </TabList>
                              </Card>
                              <TabPanel value="1"> <CompanyCardContent/> </TabPanel>
                              <TabPanel value="2">
                                  <Card style={{marginTop:"30px",padding:"20px"}}>
                                      <div style={{ display: "flex", justifyContent: "center" }}>
                                          <h4 style={{ fontSize: "15px", fontWeight: "bold", fontFamily: "inherit" }}>No data available</h4>
                                      </div>
                                  </Card>
                              </TabPanel>
                              <TabPanel value="3"> <PersonalCardContent/> </TabPanel>
                          </TabContext>
                      </div>
                  </Grid>
              </Grid>
          </Container>
    </div>
  )
}

export default Credits