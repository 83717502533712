import { SessionChallenge } from "pk-client";
import { toast } from "react-toastify";
import { history } from "utils";
import { paysackBaseUrl } from "helpers";
import { LOGIN_SUCCESS, LOGOUT, TOGGLE_LOADING, TOGGLE_SIDEBAR, STORE_REQUIRED_DATA } from "../types";
import { authRoute } from "helpers/constants";
import { empDetRoute } from "helpers/constants";
import { paysackGet } from "services";

export const loginUser = (requestData) => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        dispatch({ type: TOGGLE_SIDEBAR, payload: false });

        try {
            let sc = new SessionChallenge(paysackBaseUrl, requestData.email, requestData.password);
            let response = await sc.login();

            dispatch({ type: LOGIN_SUCCESS, payload: response.token });
            dispatch({ type: TOGGLE_LOADING, payload: false });
            dispatch(storeRequiredData());
        } catch (err) {
            toast.error("something went wrong");
            dispatch({ type: TOGGLE_LOADING, payload: false });
        }
    };
};

export const storeRequiredData = () => {
    return async (dispatch) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });
        dispatch({ type: TOGGLE_SIDEBAR, payload: false });

        const result = await paysackGet(authRoute)
        console.log(result.data._id);
        const apiResponse = await paysackGet(
            `${empDetRoute}`
        )
        console.log(apiResponse);

        if (apiResponse.data.customStatus) {
            dispatch({
                type: STORE_REQUIRED_DATA,
                payload: apiResponse.data,
                userId: result.data._id, 
                id: apiResponse.data._id,
                username: apiResponse.data.email,
            });
        }
        history.push("/app/dashboard");
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const logoutUser = () => {
    return (dispatch) => {
        dispatch({ type: LOGOUT });
    };
};
