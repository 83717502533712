import { SHOW_WALLETS_DATA, RESET_WALLETS_DATA } from "../types";

const INITIAL_STATE = {
    walletsData: [],
};

const walletsReducer = (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case SHOW_WALLETS_DATA:
            return {
                ...state,
                walletsData: payload,
            };

        case RESET_WALLETS_DATA:
            return INITIAL_STATE;
        default:
            return state;
    }
};

export default walletsReducer;
