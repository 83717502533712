import React, { useEffect, useState } from 'react'
import { Box, Button, Card, Container, Grid } from '@material-ui/core'
import CustomHeader from 'components/Headers/CustomHeader'
import { useDispatch, useSelector } from 'react-redux';
import { listAllTxns, resetTxnsData } from 'store/actions';
import Personal from '../../images/Card.png'
import { useParams } from 'react-router-dom';
import { currencyFormat } from 'utils';
import { formatDateAndTime } from 'utils';
import { history } from 'utils';

const style = {
    card: {
        padding: "20px",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    },
    img: {
        textAlign: "center",
        paddingTop: "15px"
    },
    tick: {
        fontSize: "16px",
        color: "blue"
    },
    exc: {
        fontSize: "16px",
        color: "red"
    },
    icon: {
        fontSize: "20px",
        cursor: "pointer"
    }
}

function TxnDetails() {
    const { id } = useParams()
    const dispatch = useDispatch();

    const [txn, setTxn] = useState({})

    const { txnsData } = useSelector(({ transactions: { list } }) => ({
        txnsData: list,
    }));

    useEffect(() => {
        dispatch(resetTxnsData());
        fetchMoreData();
        const filteredData = txnsData.filter((e, i) => {
            if (e._id === id) {
                return e;
            }
        })
        setTxn(filteredData[0])
    }, []);
    
    const fetchMoreData = () => {
        dispatch(listAllTxns());
    };

    const renderType = (txn) => {
        if (txn.tags) {
            if (txn.tags.length > 0) {
                if (txn.tags[0] === '') return 'Personal Expense'
            }
        }
        return 'Personal Expense'
    }

    return (
        <div>
            <CustomHeader />
            <Container maxWidth={false} component={Box} marginTop="-6rem">
                <Grid container spacing={3}>
                    <Grid item style={{ width: "100%" }}>
                        <Card style={style.card}>
                            <Grid container>
                                <Grid item lg={12} xs={12}>
                                    <Grid container>
                                        <Grid item style={style.img}>
                                            <i className='fas fa-chevron-left' style={style.icon} 
                                               onClick={() => history.goBack()}/>
                                        </Grid>
                                        <Grid item>
                                            <img src={Personal} width="50px" alt="" />
                                        </Grid>
                                        <Grid item>
                                            <h3>{currencyFormat(txn?.amount)}</h3>
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    <p><strong>Merchant Name :</strong> {txn?.merchantName}</p>
                                    <p><strong>Transaction ID :</strong> {txn?._id}</p>
                                    <p><strong>Wallet type :</strong> {txn?.amount_type}</p>
                                    <p><strong>Time & Date :</strong> {txn?.txnDate ? formatDateAndTime(txn?.txnDate):"-"}</p>
                                    <p><strong>Benefit Type :</strong> {renderType(txn)}</p>
                                    <p><strong>MCC :</strong> {txn?.mcc}</p>
                                    <p><strong>RRN :</strong> {txn?.retrievalRefNo}</p>
                                    <p><strong>Status :</strong> {txn?.txnStatus} &nbsp; {txn?.txnStatus === 'PAYMENT_SUCCESS' ? <span style={style.tick}> <i className='fa fa-check-circle' /></span> : <span style={style.exc}><i className='fa fa-exclamation-circle' /></span>}</p>
                                    <p><strong>Description :</strong> {txn?.description ? txn?.description : <span style={{ color: "red" }}>No description added</span>}</p>
                                    <p><strong>Bill Attached? :</strong> {txn?.files?.length > 0 ? "Yes" : <span style={{ color: "red" }}>No bill attached</span>}</p>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default TxnDetails